<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-badge variant="primary">
                {{ $t('Name') }}
              </b-badge>
            </b-col>
            <b-col
              v-for="(item , index) in language"
              :key="index+'-name'"
              cols="12"
            >
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name+'-name'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name+'-name'"
                  :vid="item.name+'-name'"
                >
                  <b-form-input
                    v-if="name[index]"
                    :id="item.name+'-name'"
                    :name="item.name+'-name'"
                    :value="name[index]['value']"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t(item.name)"
                    @input="inputNameLanguage($event,item.lang ,index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-2 mb-2"
            >
              <b-badge variant="success">
                {{ $t('Description') }}
              </b-badge>
            </b-col>
            <b-col
              v-for="(item , index) in language"
              :key="index+'-description'"
              cols="12"
            >
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name+'-description'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name+'-description'"
                  :vid="item.name+'-description'"
                >
                  <b-form-input
                    v-if="description[index]"
                    :id="item.name+'-description'"
                    :name="item.name+'-description'"
                    :value="description[index]['value']"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t(item.name)"
                    @input="inputDescriptionLanguage($event,item.lang ,index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput, BForm, BFormGroup, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BBadge,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      name: [],
      description: [],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id, image, name, description,
          } = this.data
          this.dataId = _id
          this.image = image
          this.name = name
          this.description = description
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }

          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.description.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.description[index].lang)
              if (indexLanguage < 0) {
                this.description.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  methods: {
    inputNameLanguage(input, lang, index) {
      console.log(input, lang, index)
      if (this.name[index].lang === lang) {
        this.name[index].value = input
      }
      console.log(this.name)
    },
    inputDescriptionLanguage(input, lang, index) {
      console.log(input, lang, index)
      if (this.description[index].lang === lang) {
        this.description[index].value = input
      }
      console.log(this.description)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.description = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            name: this.name,
            description: this.description,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/updateSlide`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/addSlide`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
